import React from 'react';
import Header from '../shared/Header';
import Sidebar from '../shared/Sidebar';
import Footer from '../shared/Footer';
import Content from '../shared/Content';

const PrivateNavigator = () => {
  return (
    <div className="wrapper">
      <Header />
      <Sidebar />
      <Content />
      <Footer />
    </div>
  );
};

export default PrivateNavigator;
