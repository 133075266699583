import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ContainerPage from './ContainerPage';
import HeaderPage from './HeaderPage';
import ContentPage from './ContentPage';
import routes from '../routes';

const Content = () => {
  return (
    <ContainerPage>
      <HeaderPage titlePage={'Productos'} />
      <ContentPage>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <>
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                </>
              )
            );
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </ContentPage>
    </ContainerPage>
  );
};

export default Content;
