const _nav = [
  {
    name: 'Dashboard',
    to: '/dashboard',
    icon: <i className="nav-icon fas fa-th" />,
    badge: {
      color: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Productos',
    to: '/products',
    icon: <i className="nav-icon fas fa-th" />,
    badge: {
      color: 'info',
      text: 'NEW'
    }
  }
];

export default _nav;
