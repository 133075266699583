import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateNavigator from './layout/PrivateNavigator';
import ProtectedRoute from './layout/ProtectedRoute';

const Login = React.lazy(() => import('./pages/Login'));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div className="pt-3 text-center">cargando...</div>}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route
            path="*"
            name="Home"
            element={
              <ProtectedRoute>
                <PrivateNavigator />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
