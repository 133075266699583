import React from 'react';

const ContentPage = ({ children }) => {
  return (
    <div className="content">
      <div className="container-fluid">{children}</div>
    </div>
  );
};

export default ContentPage;
