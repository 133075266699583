import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoIconImage from '../assets/brand/logo-icon.png';

import navigation from '../_nav';

const Sidebar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="index3.html" className="brand-link">
        <img
          src={LogoIconImage}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: '.8' }}
        />
        <span className="brand-text font-weight-light">Tanylu</span>
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {navigation.map((nav) => (
              <li className="nav-item">
                <NavLink to={nav.to} className="nav-link">
                  {nav.icon}
                  <p>{nav.name}</p>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
